import React, { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';

import { ItemMessagesListResultsMessageContentButtonTypes } from '../../ItemMessagesListResultsMessageContent.types';
import { ItemMessagesListResultsMessageContentRFAStatusTask } from './ItemMessagesListResultsMessageContentRFAStatus.types';
import { IconsEnum } from '../../../../../../../../../../../assets/icons/types';

import { useItemMessagesListResultsMessageContentButtons } from '../../hooks/useItemMessagesListResultsMessageContentButtons';

import { useAcceptResultsInTaskForm } from '../../../../../../../../../../../main/tasks/components/forms/AcceptResultsInTaskForm/hooks/useAcceptResultsInTaskForm';
import { AcceptResultsInTaskForm } from '../../../../../../../../../../../main/tasks/components/forms/AcceptResultsInTaskForm';
import { ItemMessagesListResultsMessageContentButton } from '../ItemMessagesListResultsMessageContentButton';
import { DownloadTaskResultsButton } from '../../../../../../../../../../tasks/components/buttons/DownloadTaskResultsButton';
import { AcceptTasksResultsWarning } from '../../../../../../../../../../tasks/components/modalButtons/AcceptTasksResultsModalButton/components/AcceptTasksResultsWarning';
import { ItemMessagesListResultsMessageContentDropdownButtons } from '../ItemMessagesListResultsMessageContentDropdownButtons';

import { AlertMessage } from '../../../../../../../../../../../helpers/AlertMessage';
import { CheckPermissions } from '../../../../../../../../../../../helpers/CheckPermissions';

import { TaskCache } from '../../../../../../../../../../tasks/TaskCache';

import { TasksPermissions } from '../../../../../../../../../../tasks/tasksConstants';
import {
  tasksKeys,
  words
} from '../../../../../../../../../../../locales/keys';
import { TooltipPlacement } from '../../../../../../../../../../../helpers/tooltips/tooltipsConstants';
import { PureIconButtonHelper } from '../../../../../../../../../../../helpers/buttons/PureIconButtonHelper';

const ACCEPT_RESULTS_IN_TASK_MESSAGE_FORM =
  'accept-results-in-task-message-form';

interface ItemMessagesListResultsMessageContentRFAStatusProps {
  task: ItemMessagesListResultsMessageContentRFAStatusTask;
  withoutAccept?: boolean;
  withoutClose?: boolean;
}

function ItemMessagesListResultsMessageContentRFAStatus({
  task,
  withoutAccept,
  withoutClose
}: ItemMessagesListResultsMessageContentRFAStatusProps) {
  const queryClient = useQueryClient();

  const withSupportMarks = task.supportMark > 0;

  const buttons = useMemo<ItemMessagesListResultsMessageContentButtonTypes[]>(
    () =>
      compact<ItemMessagesListResultsMessageContentButtonTypes>([
        withSupportMarks
          ? ItemMessagesListResultsMessageContentButtonTypes.ACCEPT_RESULTS
          : null,
        ItemMessagesListResultsMessageContentButtonTypes.REVISION,
        ItemMessagesListResultsMessageContentButtonTypes.SUBMIT_SOURCE_FILES
      ]),
    [withSupportMarks]
  );

  const { mainButton, dropdownButtons } =
    useItemMessagesListResultsMessageContentButtons({
      buttons
    });

  const {
    control,
    acceptResultsInTaskLoading,
    acceptResultsInTaskErrorMessage,
    handleAcceptResultsInTask,
    handleAcceptResultsInTaskAsync,
    validationErrors,
    isValid
  } = useAcceptResultsInTaskForm({
    taskUuid: task.uuid,
    taskNanoId: task.nanoId,
    withoutClose,
    withoutAccept
  });

  const handleRefetchAfterSubmit = useCallback<() => void>(
    () =>
      queryClient.invalidateQueries(TaskCache.messagesCacheKey(task.nanoId)),
    [task.nanoId, queryClient]
  );

  const handleConfirm = useCallback<() => void>(
    () => handleAcceptResultsInTask().then(() => handleRefetchAfterSubmit()),
    [handleAcceptResultsInTask, handleRefetchAfterSubmit]
  );

  const handleConfirmAsync = useCallback<() => void>(
    () =>
      handleAcceptResultsInTaskAsync().then(() => handleRefetchAfterSubmit()),
    [handleAcceptResultsInTaskAsync, handleRefetchAfterSubmit]
  );

  return (
    <div className="flex justify-center w-full text-sm mt-4 px-2">
      <div className="w-full px-2 py-4 space-y-4">
        <AcceptResultsInTaskForm
          form={ACCEPT_RESULTS_IN_TASK_MESSAGE_FORM}
          control={control}
          isLoading={acceptResultsInTaskLoading}
          isRFA={withSupportMarks}
          acceptResultsWarning={<AcceptTasksResultsWarning />}
          markValidationError={validationErrors.markValidationError}
          footer={
            <>
              <AlertMessage message={acceptResultsInTaskErrorMessage} />
              <div className="flex gap-1 justify-center">
                <CheckPermissions
                  action={TasksPermissions.READ_DOWNLOAD_TASK_RESULTS_BUTTON}
                >
                  <DownloadTaskResultsButton
                    icon={IconsEnum.DOWNLOAD_SOLID}
                    className="py-2 pl-2 pr-4 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
                    i18nText={words.download}
                    iconClassName="h-6 w-6 mr-1"
                    taskNanoId={task.nanoId}
                    tooltipI18nText={tasksKeys.downloadResults}
                    tooltipPlacement={TooltipPlacement.TOP}
                  />
                </CheckPermissions>
                <span className="relative z-10 inline-flex shadow-sm rounded-md z-auto">
                  <ItemMessagesListResultsMessageContentButton
                    main
                    buttonType={mainButton}
                    disabled={isValid === false || acceptResultsInTaskLoading}
                    task={task}
                    color="green"
                    rounded={isEmpty(dropdownButtons)}
                    onClick={handleConfirm}
                    refetchAfterSubmit={handleRefetchAfterSubmit}
                  />

                  <ItemMessagesListResultsMessageContentDropdownButtons
                    buttonColor="green"
                    buttons={dropdownButtons}
                    task={task}
                    refetchAfterSubmit={handleRefetchAfterSubmit}
                  />
                </span>
                <CheckPermissions
                  action={
                    TasksPermissions.READ_ACCEPT_RESULTS_IN_TASK_ASYNC_BUTTON
                  }
                >
                  <span className="relative z-10 inline-flex shadow-sm rounded-md z-auto">
                    <PureIconButtonHelper
                      className="text-gray-800 bg-rainbow-to-r hover:text-black relative inline-flex items-center px-4 py-2 rounded-md text-sm font-medium focus:z-10 text-white gap-1"
                      icon={IconsEnum.ROCKET_OUTLINE}
                      i18nText={tasksKeys.buttons.accept}
                      onClick={handleConfirmAsync}
                      disabled={isValid === false || acceptResultsInTaskLoading}
                    />
                  </span>
                </CheckPermissions>
              </div>
            </>
          }
        />
      </div>
    </div>
  );
}

export default ItemMessagesListResultsMessageContentRFAStatus;
