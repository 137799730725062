import React, { useState, useCallback, ChangeEventHandler } from 'react';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import pick from 'lodash/pick';
import omit from 'lodash/omit';

import { SearchFilterNanoID } from '../../../../../../searchFilters/searchFiltersTypes';

import {
  FETCH_SEARCH_FILTERS_QUERY,
  FetchSearchFiltersQueryResponse
} from '../../../../../../searchFilters/queries/fetchSearchFilters.query';
import {
  CREATE_SEARCH_FILTER_QUERY,
  CreateSearchFilterQueryResponse
} from '../../../../../../searchFilters/queries/createSearchFilter.query';

import { useSearchFilters } from '../../../../../../searchFilters/hooks/useSearchFilters';
import { useCreateSearchFilter } from '../../../../../../searchFilters/hooks/useCreateSearchFilter';

import { useTranslate } from '../../../../../../../common/hooks/useTranslate';

import {
  ItemMessagesFilters,
  ItemMessagesListFilterMessages
} from '../../../../list/ItemMessagesList';

import { ItemMessagesSavedFiltersButtonFilterItem } from '../ItemMessagesSavedFiltersButtonFilterItem';

import { LoadingSkeleton } from '../../../../../../../helpers/LoadingSkeleton';
import { PureButtonHelper } from '../../../../../../../helpers/buttons/PureButtonHelper';

import { filtersKeys, words } from '../../../../../../../locales/keys';

import { SearchFilterCache } from '../../../../../../searchFilters/SearchFilterCache';
import { SearchFiltersScopes } from '../../../../../../searchFilters/searchFiltersConstants';

const enum ItemMessagesSavedFiltersButtonContentSteps {
  MAIN = 'main',
  SAVE_FILTERS = 'save_filters'
}

const scope = SearchFiltersScopes.ITEM_MESSAGES_INDEX;

const ITEM_MESSAGES_PAGE_FILTERS = ['project', 'task'];

interface ItemMessagesSavedFiltersButtonContentProps {
  messagesFilters: ItemMessagesFilters;
  filterMessages: ItemMessagesListFilterMessages;
  closePopover: () => void;
}

function ItemMessagesSavedFiltersButtonContent({
  filterMessages,
  messagesFilters,
  closePopover
}: ItemMessagesSavedFiltersButtonContentProps) {
  const { t } = useTranslate();

  const { searchFilters, searchFiltersFetched, searchFiltersError } =
    useSearchFilters<FetchSearchFiltersQueryResponse<ItemMessagesFilters>>({
      cacheKey: SearchFilterCache.indexCacheKey(),
      query: FETCH_SEARCH_FILTERS_QUERY,
      initialFilters: {
        scope: { in: [scope] },
        self: true
      }
    });

  const { createSearchFilter, createSearchFilterLoading } =
    useCreateSearchFilter<ItemMessagesFilters, CreateSearchFilterQueryResponse>(
      {
        query: CREATE_SEARCH_FILTER_QUERY
      }
    );

  const [mode, setMode] = useState<ItemMessagesSavedFiltersButtonContentSteps>(
    ItemMessagesSavedFiltersButtonContentSteps.MAIN
  );

  const [filterName, setFilterName] = useState<string>('');

  const handleFilterNameChange = useCallback<
    ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      setFilterName(event.target.value);
    },
    [setFilterName]
  );

  const handleSwitchToSaveFiltersMode = useCallback<() => void>(() => {
    setMode(ItemMessagesSavedFiltersButtonContentSteps.SAVE_FILTERS);
  }, []);

  const handleSwitchToMainMode = useCallback<() => void>(() => {
    setMode(ItemMessagesSavedFiltersButtonContentSteps.MAIN);
  }, []);

  const handleSaveFilters = useCallback<() => void>(() => {
    createSearchFilter({
      data: omit(messagesFilters, ITEM_MESSAGES_PAGE_FILTERS),
      name: filterName,
      scope
    }).then(() => {
      handleSwitchToMainMode();
    });
  }, [filterName, messagesFilters, handleSwitchToMainMode, createSearchFilter]);

  const handleSelectItem = useCallback<
    (searchFilterNanoId: SearchFilterNanoID) => void
  >(
    (searchFilterNanoId) => {
      const searchFilter = find(
        searchFilters,
        ({ nanoId }) => searchFilterNanoId === nanoId
      );
      if (searchFilter?.data) {
        filterMessages({
          ...pick(messagesFilters, ITEM_MESSAGES_PAGE_FILTERS),
          ...searchFilter.data
        });
        closePopover();
      }
    },
    [messagesFilters, searchFilters, filterMessages, closePopover]
  );

  const hideSavedFiltersBlock =
    isEmpty(searchFilters) && searchFiltersFetched && !searchFiltersError;

  return (
    <div>
      {mode === ItemMessagesSavedFiltersButtonContentSteps.MAIN ? (
        <>
          <LoadingSkeleton count={2} loaded={searchFiltersFetched}>
            {hideSavedFiltersBlock ? null : (
              <ul className="px-1">
                {searchFilters.map(({ name, nanoId }) => (
                  <ItemMessagesSavedFiltersButtonFilterItem
                    text={name}
                    key={nanoId}
                    searchFilterNanoId={nanoId}
                    onSelectItem={handleSelectItem}
                  />
                ))}
              </ul>
            )}
          </LoadingSkeleton>
          {hideSavedFiltersBlock ? null : (
            <hr className="my-2 border-gray-200 dark:border-gray-600" />
          )}
          <div className="px-1">
            <PureButtonHelper
              className="py-1.5 px-3 rounded-md whitespace-nowrap text-sm leading-5 text-white bg-blue-500 hover:bg-blue-600 focus:ring-offset-0 w-full"
              i18nText={filtersKeys.saveCurrentFilters}
              onClick={handleSwitchToSaveFiltersMode}
            />
          </div>
        </>
      ) : null}
      {mode === ItemMessagesSavedFiltersButtonContentSteps.SAVE_FILTERS ? (
        <>
          <div className="px-2">
            <div>
              <input
                className="border-0 border-b border-gray-300 dark:border-gray-700 p-0 focus:ring-0 focus:border-blue-500 w-full dark:focus:placeholder-gray-500 placeholder-gray-400 focus:placeholder-gray-300 text-sm bg-transparent"
                type="text"
                value={filterName || ''}
                autoFocus
                placeholder={t(filtersKeys.enterFilterName)}
                onChange={handleFilterNameChange}
              />
            </div>
          </div>
          <div className="px-1 mt-2 flex gap-1">
            <PureButtonHelper
              className="py-1 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
              i18nText={words.save}
              disabled={!filterName || createSearchFilterLoading}
              onClick={handleSaveFilters}
            />
            <PureButtonHelper
              className="py-1 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
              i18nText={words.cancel}
              onClick={handleSwitchToMainMode}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}

export default ItemMessagesSavedFiltersButtonContent;
