import React, { useCallback, useEffect } from 'react';

import {
  TaskNanoID,
  TaskProjectUUID,
  TaskUUID
} from '../../../../../tasksTypes';
import { MessageID } from '../../../../../../messages/messagesTypes';
import { InfiniteIndexQueryOnSuccess } from '../../../../../../common/hooks/base/reactQuery/useInfiniteIndexQuery';
import { CheckableMessagesProps } from '../../../../../../../common/hooks/useMessagesCheckable';

import {
  FETCH_FIN_TASK_MESSAGES,
  FetchFinTaskMessagesQueryResponse
} from '../../../../../../messages/queries/fetchFinTaskMessages.query';
import {
  FETCH_USERS_MESSAGES_AUTHORS_QUERY,
  FetchUsersMessagesAuthorsQueryResponse
} from '../../../../../../users/queries/fetchUsersMessagesAuthors.query';
import {
  FETCH_SELECT_FIELD_FIN_MESSAGES,
  FetchSelectFieldFinMessagesQueryResponse
} from '../../../../../../messages/queries/fetchSelectFieldFinMessages.query';

import { useFinUsers } from '../../../../../../users/hooks/useFinUsers';
import { useMessagesWithCustomPagination } from '../../../../../../messages/hooks/useMessagesWithCustomPagination';
import { useJumpToMessage } from '../../../../../../messages/components/list/ItemMessagesList/hooks/useJumpToMessage';
import { useMessages } from '../../../../../../messages/hooks/useMessages';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import {
  ItemMessagesList,
  ItemMessagesListMessages,
  ItemMessagesListOnRemoveSendingMessage
} from '../../../../../../messages/components/list/ItemMessagesList';
import { ItemMessagesHeader } from '../../../../../../messages/components/headers/ItemMessagesHeader';
import { PinnedMessageWithTodoList } from '../../../../../../messages/components/list/PinnedMessageWithTodoList';

import { CheckPermissions } from '../../../../../../../helpers/CheckPermissions';

import { TaskCache } from '../../../../../TaskCache';
import { UserCache } from '../../../../../../users/UserCache';

import { MessagesPermissions } from '../../../../../../messages/messagesConstants';

import { messagesKeys } from '../../../../../../../locales/keys';

type ItemMessagesListProps = {
  taskNanoId: TaskNanoID;
  projectUuid: TaskProjectUUID;
  taskUuid: TaskUUID;
  onReplyMessage?: (messageId: MessageID) => void;
  sendingMessages: ItemMessagesListMessages;
  onFetchMessagesSuccess: InfiniteIndexQueryOnSuccess<FetchFinTaskMessagesQueryResponse>;
  onRemoveSendingMessage: ItemMessagesListOnRemoveSendingMessage;
  togglePinnedMessagesView: () => void;
  pinnedMessagesView: boolean;
  withWhiteboardMessages?: boolean;
} & CheckableMessagesProps;

function TaskMessagesContentMessagesList({
  taskNanoId,
  projectUuid,
  taskUuid,
  onReplyMessage,
  sendingMessages,
  onFetchMessagesSuccess,
  onRemoveSendingMessage,
  togglePinnedMessagesView,
  pinnedMessagesView,
  withWhiteboardMessages,
  checkedMessages,
  onSetCheckedMessage
}: ItemMessagesListProps) {
  const currentUser = useCurrentUser();

  const whiteboardKeyId = withWhiteboardMessages ? undefined : { isNull: true };

  const {
    messages,
    messagesError,
    messagesFetched,
    messagesFetchingNextPage,
    messagesFetchingPreviousPage,
    messagesIsPlaceholderData,
    hasNextMessagesPage,
    hasPreviousMessagesPage,
    loadMoreMessages,
    loadMorePreviousMessages,
    changeMessagesFilters,
    messagesFilters,
    filterMessages
  } = useMessagesWithCustomPagination<FetchFinTaskMessagesQueryResponse>({
    cacheKey: TaskCache.messagesCacheKey(taskNanoId),
    query: FETCH_FIN_TASK_MESSAGES,
    initialFilters: {
      taskNanoId,
      whiteboardKeyId
    },
    options: { onSuccess: onFetchMessagesSuccess }
  });

  const { messages: lastMessages, messagesFetched: lastMessagesFetched } =
    useMessages<FetchSelectFieldFinMessagesQueryResponse>({
      cacheKey: TaskCache.lastMessagesCacheKey(taskNanoId),
      query: FETCH_SELECT_FIELD_FIN_MESSAGES,
      initialFilters: {
        taskNanoId,
        whiteboardKeyId
      },
      initialLimit: 1
    });

  useEffect(() => {
    if (taskNanoId !== messagesFilters.taskNanoId) {
      changeMessagesFilters({
        taskNanoId
      });
    }
  }, [changeMessagesFilters, messagesFilters.taskNanoId, taskNanoId]);

  const { users, usersError, usersFetched, usersTotalCount } =
    useFinUsers<FetchUsersMessagesAuthorsQueryResponse>({
      cacheKey: UserCache.usersMessagesByTask(taskNanoId),
      query: FETCH_USERS_MESSAGES_AUTHORS_QUERY,
      initialFilters: {
        messagesInTaskNanoId: { eq: taskNanoId }
      },
      initialLimit: 100
    });

  const handleClickPinnedButton = useCallback<() => void>(() => {
    togglePinnedMessagesView();
    if (!pinnedMessagesView && !messagesFilters.pinned) {
      changeMessagesFilters({
        ...messagesFilters,
        pinned: true
      });
    }

    if (pinnedMessagesView && messagesFilters.pinned) {
      changeMessagesFilters({
        ...messagesFilters,
        pinned: false
      });
    }
  }, [
    changeMessagesFilters,
    messagesFilters,
    pinnedMessagesView,
    togglePinnedMessagesView
  ]);

  const { jumpToLastMessage, jumpToMessage } = useJumpToMessage({
    filterMessages,
    messages,
    messagesFetched,
    messagesFilters
  });

  const goToLastMessage = useCallback(() => {
    if (lastMessages.length > 0) {
      jumpToLastMessage(lastMessages[0]);
    }
  }, [jumpToLastMessage, lastMessages]);

  return (
    <>
      {pinnedMessagesView ? (
        <PinnedMessageWithTodoList
          i18nTitle={messagesKeys.pinnedMessages}
          onBackClick={handleClickPinnedButton}
          messages={messages}
          messagesErrorMessage={messagesError}
          messagesFetched={messagesFetched}
          messagesIsPlaceholderData={messagesIsPlaceholderData}
          taskNanoId={taskNanoId}
          messagesCacheKey={TaskCache.messagesCacheKey(taskNanoId)}
        />
      ) : (
        <ItemMessagesList
          messages={messages}
          messagesCacheKey={TaskCache.messagesCacheKey(taskNanoId)}
          messagesErrorMessage={messagesError}
          messagesFetched={messagesFetched}
          messagesFetchingNextPage={messagesFetchingNextPage}
          messagesFetchingPreviousPage={messagesFetchingPreviousPage}
          onReplyMessage={onReplyMessage}
          hasNextMessagesPage={hasNextMessagesPage}
          hasPreviousMessagesPage={hasPreviousMessagesPage}
          loadMoreMessages={loadMoreMessages}
          loadMorePreviousMessages={loadMorePreviousMessages}
          messagesIsPlaceholderData={messagesIsPlaceholderData}
          forwardMessageProjectUuid={projectUuid}
          forwardMessageTaskUuid={taskUuid}
          sendingMessages={sendingMessages}
          onRemoveSendingMessage={onRemoveSendingMessage}
          goToLastMessage={goToLastMessage}
          checkedMessages={checkedMessages}
          onSetCheckedMessage={onSetCheckedMessage}
          jumpToMessage={jumpToMessage}
          markedUnhappyMessages={
            !messagesFilters.visibleForClient &&
            currentUser.hasPermissions(
              MessagesPermissions.READ_MESSAGE_UNHAPPY_MESSAGE
            )
          }
        />
      )}

      <CheckPermissions action={MessagesPermissions.READ_MESSAGES_HEADER}>
        <ItemMessagesHeader
          key={taskNanoId}
          taskNanoId={taskNanoId}
          messagesFilters={messagesFilters}
          filterMessages={filterMessages}
          users={users}
          usersError={usersError}
          usersFetched={usersFetched}
          usersTotalCount={usersTotalCount}
          onClickPinnedButton={handleClickPinnedButton}
          messages={messages}
          messagesFetched={messagesFetched || messagesIsPlaceholderData}
          lastMessages={lastMessages}
          lastMessagesFetched={lastMessagesFetched}
        />
      </CheckPermissions>
    </>
  );
}

export default TaskMessagesContentMessagesList;
