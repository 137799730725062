import cl from 'classnames';
import includes from 'lodash/includes';

import { InvoiceStatuses } from '../../../../../../../../invoices/invoicesTypes';
import { FetchMessagesCacheKey } from '../../../../../../../messagesTypes';
import { CustomFieldLocations } from '../../../../../../../../customFields/customFieldsTypes';

import { ItemMessagesListInvoiceMessageItem } from '../../ItemMessagesListInvoiceMessage.types';
import {
  ItemMessagesListInvoiceMessageHeaderButtons,
  ItemMessagesListInvoiceMessageHeaderButtonsMessage
} from '../ItemMessagesListInvoiceMessageHeaderButtons';

import { useCurrentUser } from '../../../../../../../../../auth/hooks/useAuth';

import { PayInvoiceModalButton } from '../../../../../../../../invoices/components/modalButton/PayInvoiceModalButton';
import { UpdateBillingInfoInvoiceModalButton } from '../../../../../../../../invoices/components/modalButton/UpdateBillingInfoInvoiceModalButton';
import { InvoiceItemsTableBlock } from '../../../../../../../../invoices/components/InvoiceItemsTableBlock';
import { ItemMessagesListInvoiceMessageHeader } from '../ItemMessagesListInvoiceMessageHeader';
import { InvoiceCustomFieldsList } from '../../../../../../../../invoiceCustomFields/helpers/InvoiceCustomFieldsList';

import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';
import { AvBillingInfoHelper } from '../../../../../../../../avBillingInfos/helpers/AvBillingInfoHelper';
import { BillingInfoHelper } from '../../../../../../../../billingInfos/helpers/BillingInfoHelper';
import { CheckPermissions } from '../../../../../../../../../helpers/CheckPermissions';
import { Icon } from '../../../../../../../../../helpers/Icon';
import { LoadingSkeleton } from '../../../../../../../../../helpers/LoadingSkeleton';
import { TeamUpsalersHelper } from '../../../../../../../../teamsUpsalers/helpers/TeamUpsalersHelper';
import { TextField } from '../../../../../../../../../helpers/FormFields/TextField';
import { AvBillingInfoPaymentMethodHelper } from '../../../../../../../../avBillingInfos/helpers/AvBillingInfoPaymentMethodHelper';

import { getAppIcon } from '../../../../../../../../../app/utils/getAppIcon';

import {
  billingInfosKeys,
  invoicesKeys,
  words
} from '../../../../../../../../../locales/keys';

import {
  InvoicesPermissions,
  bgCrossedInvoiceStatuses
} from '../../../../../../../../invoices/invoicesConstants';
import { MessagesPermissions } from '../../../../../../../messagesConstants';
import { InvoiceCache } from '../../../../../../../../invoices/InvoiceCache';

const invoicesCacheKeys = [InvoiceCache.itemsWithBitInvoicesCacheKey()];

const { appIcon, appIconBgColorClassName } = getAppIcon();

interface ItemMessagesListInvoiceMessageDetailedViewProps {
  isOpen: boolean;
  message: ItemMessagesListInvoiceMessageItem &
    ItemMessagesListInvoiceMessageHeaderButtonsMessage;
  messagesCacheKey: FetchMessagesCacheKey;
  toggleIsOpen: () => void;
}

function ItemMessagesListInvoiceMessageDetailedView({
  isOpen,
  message,
  messagesCacheKey,
  toggleIsOpen
}: ItemMessagesListInvoiceMessageDetailedViewProps) {
  const currentUser = useCurrentUser();

  const invoice = message?.bitInvoice;
  const invoiceError = message?.bitInvoiceErrorMessage;
  const invoiceFetched = message?.bitInvoiceFetched;

  return (
    <>
      <div
        className={cl('relative bg-gray-50 dark:bg-gray-850', {
          'bg-crossed': includes(
            bgCrossedInvoiceStatuses,
            message.invoice?.status
          )
        })}
      >
        <ItemMessagesListInvoiceMessageHeaderButtons
          isOpen={isOpen}
          message={message}
          messagesCacheKey={messagesCacheKey}
          toggleIsOpen={toggleIsOpen}
        />

        <div className="max-w-3xl mx-auto flex gap-6 px-6 py-8">
          <div className="flex-1">
            <Icon
              icon={appIcon}
              className={cl(
                'object-cover w-20 h-20 rounded text-white p-5',
                appIconBgColorClassName
              )}
            />
          </div>

          <ItemMessagesListInvoiceMessageHeader message={message} />
        </div>
      </div>

      <LoadingSkeleton loaded={invoiceFetched} count={5}>
        {invoice ? (
          <>
            {/* Billing info */}
            <div className="max-w-3xl mx-auto px-6 pt-8 pb-4">
              <div className="flex gap-6 mb-4">
                {/* Bill from */}
                <div className="flex-1">
                  <CheckPermissions
                    action={
                      MessagesPermissions.READ_MESSAGE_INVOICE_AV_BILLING_INFO
                    }
                  >
                    <div className="text-xs">
                      <AvBillingInfoHelper
                        avBillingInfo={invoice.avInvoiceBillingInfo}
                        i18nLabel={billingInfosKeys.billFromColon}
                        i18nWarning={
                          billingInfosKeys.receiverBillingInfoWillFillAutomaticallyAfterPayerBillingInfoIsProvided
                        }
                      />
                    </div>
                  </CheckPermissions>

                  <CheckPermissions
                    action={
                      MessagesPermissions.READ_MESSAGE_INVOICE_AV_BILLING_INFO_CUSTOM_FIELDS
                    }
                  >
                    <InvoiceCustomFieldsList
                      invoiceCustomFields={invoice?.invoiceCustomFields}
                      customFieldsLocation={CustomFieldLocations.BILL_FROM}
                    />
                  </CheckPermissions>
                </div>

                {/* Bill to */}
                <div className="flex-1">
                  <CheckPermissions
                    action={
                      MessagesPermissions.READ_MESSAGE_INVOICE_BILLING_INFO
                    }
                  >
                    <div className="text-xs">
                      <BillingInfoHelper
                        billingInfo={invoice.invoiceBillingInfo}
                        i18nLabel={billingInfosKeys.billToColon}
                        i18nWarning={
                          billingInfosKeys.provideYourBillingInfoInOrderToCreateInvoiceForTheProject
                        }
                      />

                      {/* Change billing info */}
                      {invoice.generalLedger?.company?.nanoId &&
                      invoice?.status === InvoiceStatuses.SENT ? (
                        <CheckPermissions
                          action={
                            MessagesPermissions.READ_MESSAGE_INVOICE_UPDATE_INVOICE_BILLING_INFO_MODAL_BUTTON
                          }
                        >
                          <div className="mt-1.5">
                            <UpdateBillingInfoInvoiceModalButton
                              invoiceId={invoice.id}
                              companyNanoId={
                                invoice.generalLedger.company.nanoId
                              }
                              cacheKeys={invoicesCacheKeys}
                            />
                          </div>
                        </CheckPermissions>
                      ) : null}
                    </div>
                  </CheckPermissions>

                  <CheckPermissions
                    action={
                      MessagesPermissions.READ_MESSAGE_INVOICE_BILLING_INFO_CUSTOM_FIELDS
                    }
                  >
                    <InvoiceCustomFieldsList
                      wrapperClassName="mt-1 space-y-1"
                      invoiceCustomFields={invoice?.invoiceCustomFields}
                      customFieldsLocation={CustomFieldLocations.BILL_TO}
                    />
                  </CheckPermissions>
                </div>
              </div>

              {/* Client manager */}
              <CheckPermissions
                action={MessagesPermissions.READ_MESSAGE_INVOICE_TEAM_UPSALERS}
              >
                <TeamUpsalersHelper
                  teamUuid={invoice.generalLedger?.company?.uuid}
                  i18nLabel={words.accountManager}
                />
              </CheckPermissions>

              {/* Payment method */}
              <CheckPermissions
                action={MessagesPermissions.READ_MESSAGE_INVOICE_PAYMENT_METHOD}
              >
                <AvBillingInfoPaymentMethodHelper
                  paymentMethod={invoice?.avInvoiceBillingInfo?.paymentMethod}
                />
              </CheckPermissions>
            </div>

            <div className="max-w-3xl mx-auto px-2 sm:px-6 pt-4 pb-8">
              {/* Items */}
              <CheckPermissions
                action={MessagesPermissions.READ_MESSAGE_INVOICE_ITEMS}
              >
                {invoice && (
                  <InvoiceItemsTableBlock
                    cacheKeys={invoicesCacheKeys}
                    invoice={invoice}
                    withGroupBy={currentUser.hasPermissions(
                      MessagesPermissions.READ_MESSAGE_INVOICE_ITEMS_GROUP_BY_FIELD
                    )}
                    withGroupByItemCategoriesOption={currentUser.hasPermissions(
                      MessagesPermissions.READ_MESSAGE_INVOICE_ITEMS_GROUP_BY_ITEM_CATEGORIES_OPTION
                    )}
                    // withGroupByItemTagsOption={currentUser.hasPermissions(
                    //   MessagesPermissions.READ_MESSAGE_INVOICE_ITEMS_GROUP_BY_ITEM_TAGS_OPTION
                    // )}
                    withGroupByItemsOption={currentUser.hasPermissions(
                      MessagesPermissions.READ_MESSAGE_INVOICE_ITEMS_GROUP_BY_ITEMS_OPTION
                    )}
                    withGroupByProjectsOption={currentUser.hasPermissions(
                      MessagesPermissions.READ_MESSAGE_INVOICE_ITEMS_GROUP_BY_PROJECTS_OPTION
                    )}
                    withGroupByTasksOption={currentUser.hasPermissions(
                      MessagesPermissions.READ_MESSAGE_INVOICE_ITEMS_GROUP_BY_TASKS_OPTION
                    )}
                    withToggleMyItemsOnly={currentUser.hasPermissions(
                      MessagesPermissions.READ_MESSAGE_INVOICE_ITEMS_MY_ITEMS_ONLY_FIELD
                    )}
                    withAmountDueCustomFields={currentUser.hasPermissions(
                      MessagesPermissions.READ_MESSAGE_INVOICE_AMOUNT_DUE_CUSTOM_FIELDS
                    )}
                    withPrepayment={currentUser.hasPermissions(
                      InvoicesPermissions.READ_INVOICE_ITEMS_TABLE_PREPAYMENT_FIELD
                    )}
                  />
                )}
              </CheckPermissions>

              {/* Notes */}
              {invoice.notes && (
                <CheckPermissions
                  action={MessagesPermissions.READ_MESSAGE_INVOICE_NOTES}
                >
                  <TextField
                    i18nLabel={invoicesKeys.notesColon}
                    wrapperClassName="mt-4"
                    value={invoice.notes}
                  />
                </CheckPermissions>
              )}

              {/* Terms */}
              {invoice.terms && (
                <CheckPermissions
                  action={MessagesPermissions.READ_MESSAGE_INVOICE_TERMS}
                >
                  <TextField
                    i18nLabel={invoicesKeys.termsColon}
                    value={invoice.terms}
                  />
                </CheckPermissions>
              )}

              <CheckPermissions
                action={
                  MessagesPermissions.READ_MESSAGE_INVOICE_TERMS_AND_NOTES_CUSTOM_FIELDS
                }
              >
                <div className="mt-2">
                  <InvoiceCustomFieldsList
                    invoiceCustomFields={invoice?.invoiceCustomFields}
                    customFieldsLocation={CustomFieldLocations.NOTES_AND_TERMS}
                  />
                </div>
              </CheckPermissions>

              {/* Pay */}
              {invoice.showPayCardButton && invoice.nanoId && (
                <CheckPermissions
                  action={MessagesPermissions.READ_MESSAGE_INVOICE_PAY_BUTTON}
                >
                  <div className="mt-4 flex items-center justify-center gap-0.5">
                    <PayInvoiceModalButton
                      invoiceNanoId={invoice.nanoId}
                      cacheKeys={[messagesCacheKey]}
                      className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
                      i18nText={words.pay}
                      disabled={invoice.disabledPayCardButton}
                    />
                  </div>
                </CheckPermissions>
              )}
            </div>
          </>
        ) : null}
      </LoadingSkeleton>

      <AlertMessage message={invoiceError} />
    </>
  );
}

export default ItemMessagesListInvoiceMessageDetailedView;
